a { 
    color: inherit;
    text-decoration: underline;
}

html {
    /* Prevent vertical scrollbar from pushing content */
    scrollbar-gutter: stable;
}

body {
    background-color:#000000;
    overflow-x: clip;
}

.signUpForm {
    max-width: 500px;
}

.verifyForm {
    max-width: 450px;
    margin: 0 auto;
}

.left {
    padding-left: 10px;
    margin-top: 20px;
    margin-bottom: 30px;
    line-height: 0.9em;
}

.right {
    min-height: 200px;
    padding-left: 60px;
    color: #555;
    margin-bottom: 200px;
}

h1, h2, h3, h4, h5, h6, p {
    /* devil words */
    color: #666;
}

.text {
    color: #666;
}

.error {
    color: #555;
}

.head-space {
    margin-top: 30px;
}

.foot-space {
    margin-bottom: 300px;
}

.review-box {
    margin: 30px 0px;
}

.review {
    margin-top: 10px;
    font-size: 1.5em;
}

.review-author {
    font-weight: 600;
    text-align: right;
    padding-right: 40px;
}

.lowercase {
    text-transform: lowercase;
}

.navbar {
    height: 70px;
}

.navbar-search {
    height: 116px;
}

.navbar-friends {
    height: 174px;
}

.navbar-brand {
    margin: 0;
    padding: 0;
}

@media only screen and (min-width: 992px) {
    .landing-row {
        height: 100vh;
    }

    .left {
        display: flex;
        justify-content: center;
        font-size: 200px;
    }

    .right {
        margin-bottom: 0px;
    }
} 

.header {
    color: #ffa52aff;
}

.orange {
    color: #ffa52aff;
}

.gray {
    color: #666;
}

.bare-column {
    margin: 0px;
}

.brand-column {
    padding-right: 0px;
}

#nav-link-row {
    margin-left: 0px;
    margin-right: 0px;
}

.home-nav {
    min-width: 38px;
    height: 54px;
    background: url("home_icon_gray30.png") no-repeat;
    background-position: center;
    border-bottom-style: solid;
    border-color: #00000000;
    margin: 0px 0px;
    padding: 0px 0px;
}

.home-nav:hover {
    background: url("home_icon.png") no-repeat;
    background-position: center;
    border-bottom-style: solid;
    border-color: white;
}

.home-nav-current {
    min-width: 38px;
    height: 54px;
    background: url("home_icon_selected.png") no-repeat;
    background-position: center;
    border-bottom-style: solid;
    border-color: #ffa52aff;
    margin: 0px 0px;
    padding: 0px 0px;
}

.search-nav {
    min-width: 38px;
    height: 54px;
    background: url("search_icon_gray30.png") no-repeat;
    background-position: center;
    border-bottom-style: solid;
    border-color: #00000000;
    margin: 0px 0px;
    padding: 0px 0px;
}

.search-nav:hover {
    background: url("search_icon.png") no-repeat;
    background-position: center;
    border-bottom-style: solid;
    border-color: white;
}

.search-nav-current {
    min-width: 38px;
    height: 54px;
    background: url("search_icon_selected.png") no-repeat;
    background-position: center;
    border-bottom-style: solid;
    border-color: #ffa52aff;
    margin: 0px 0px;
    padding: 0px 0px;
}

.friends-nav {
    min-width: 38px;
    height: 54px;
    background: url("friends_icon_gray30.png") no-repeat;
    background-position: center;
    border-bottom-style: solid;
    border-color: #00000000;
    margin: 0px 0px;
    padding: 0px 0px;
}

.friends-nav:hover {
    background: url("friends_icon.png") no-repeat;
    background-position: center;
    border-bottom-style: solid;
    border-color: white;
}

.friends-nav-current {
    min-width: 38px;
    height: 54px;
    background: url("friends_icon_selected.png") no-repeat;
    background-position: center;
    border-bottom-style: solid;
    border-color: #ffa52aff;
    margin: 0px 0px;
    padding: 0px 0px;
}

.orange-btn {
    background-color: #ffa52aff;
    border-color: #ffa52aff;
}

.orange-btn:hover {
    background-color: #ffb95aff;
    border-color: #ffb95aff;
}

.orange-btn:focus {
    background-color: #ffd091ff;
    border-color: #ffd091ff                 ;
}

.orange-btn:disabled {
    background-color: rgb(53, 53, 53);
    border-color: rgb(53, 53, 53);
}

.orange-btn:active {
    background-color: rgb(255, 222, 178);
    border-color: rgb(255, 222, 178);
}

.no-validation-border:focus {
    box-shadow: 0px 0px 0px 4px #007bff44 !important;
}

.no-validation-border {
    border-style: none !important;
}

.link-button {
    /* Source: https://stackoverflow.com/questions/1367409/how-to-make-button-look-like-a-link */
    background: none!important;
    border: none;
    padding: 0!important;
    text-decoration: underline;
    cursor: pointer;
}

.link-button:hover {
    color: #0056b3;
}

.memo {
    display: inline-block;
    width: 500px;
}
  
/* The typing effect */
@keyframes typing {
    from { width: 0px; }
    to { width: 7760px; } /* 28.014px per character */
}

/* The typewriter cursor effect */
@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #00226633; }
}

.typewriter-test {
    width: 0%;
    white-space: nowrap;
    overflow: hidden;
    border-right: .12em solid #00226633; /* The typwriter cursor */
    color: #FFFFFF99;
    font-size: 40px;
    font-weight: bold;
    font-family:'Source Code Pro', monospace;
    letter-spacing: .1em; /* Adjust as needed */
    margin-left: -10000px;
    animation: 
      typing 40s steps(277, end) 1s,
      blink-caret 1.25s step-end infinite;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #aaaaaaff !important;
    opacity: 1; /* Firefox */
}

.large-input {
    max-width: 500px;
}

.medium-input {
    max-width: 300px;
}

.small-input {
    max-width: 100px;
}

.paragraph-column {
    max-width: 400px;
}

.sticky-nav {
    position: sticky;
    top: 0;
}

.userDropDown {
    background-color: #111;
}

#username {
    color: white;
    font-size: 1.5em;
    margin-top: 0em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight:400;
}

#userNumber {
    color: #555;
    font-size: 1.5em;
    margin-top: 0em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight:400;
}

#username-xl {
    color: white;
    font-size: 1.5em;
    margin-top: 0em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight:400;
}

#userNumber-xl {
    color: #555;
    font-size: 1.5em;
    margin-top: 0em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight:400;
}

#fullName-xs {
    color: #777;
    font-size: 1.2em;
    margin-top: 0em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight:400;
}

#fullName-xl {
    color: #555;
    font-size: 1.5em;
    margin-top: 0em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight:400;
}

#fullName {
    color: #999;
    font-size: 1em;
    font-weight: 400;
    padding-left: 15px;
}

.userStatusBtn {
    width: 150px;
    height: 36px;
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 1em;
    font-weight: 600;
}

.selfStatusBtn {
    color: #555;
    background-color: #111;
}

.followerColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.followerCount {
    color: #999;
    font-size: 1.5em;
}

.followerLabel {
    color: #999;
}

.followerCount-xs {
    color: #999;
    font-size: 1.3em;
}

.followerLabel-xs {
    color: #999;
    font-size: 0.9em;
}

.rightSummary-xs {
    max-width: 170px;
}

.rightSummary {
    margin-left: 40px;
    flex-grow:1;
    min-width: 200px;
    max-width: 300px;
}

.outline-link {
    border-color: #666;
    border-style: solid;
    border-radius: 20px;
    border-width: 1px;
}

.outline-link:hover {
    /* background-color: #ffa52aff; */
    background-color: #111;
}

.search-result {
    border-bottom: solid thin #222;
}

.search-result-image {
    max-height: 250px;
}

.result-media-type {
    font-size: 0.8em;
    margin-bottom: 5px;
    color: #888;
}

.result-title {
    font-size: 1em;
    color: #ddd;
}

.result-details {
    font-size: 0.8em;
    color: #888;
}

.result-user-count {
    margin-bottom: 5px;
    font-size: 0.8em;
    color: #888;
}

.result-stat-box {
}

.result-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 700px;
}

.no-results-header {
    font-size: 1.3em;
}

.no-results-text {
    font-size: 0.8em;
}

@media only screen and (min-width: 576px) {
    .result-title {
        font-size: 1.3em;
        color: #ddd;
    }

    .result-details {
        font-size: 1em;
    }

    .result-media-type {
        font-size: 1em;
    }
    
    .result-stat-box {
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: 768px) {
    .result-title {
        font-size: 1.5em;
        color: #ddd;
    }

    .result-details {
        font-size: 1.2em;
    }

    .result-media-type {
        font-size: 1.2em;
    }

    .no-results-header {
        font-size: 1.8em;
    }
    
    .no-results-text {
        font-size: 1em;
    }
}

.search-form {
    width: 100%;
}

.shelf-name {
    display: block;
    width: auto;
}

.shelf-container {
    width: 100%;
    height: 282 px; /* Natural height, but hardcoding for clarity */
    position: relative;
}

.shelf {
    width: 100%;
    overflow: auto;
    flex-wrap: nowrap;
    scrollbar-width: none; /* Firefox */
    margin: 0px;
    height: 282px; /* Natural height, but hardcoding for clarity */
}

.shelf-overlay {
    width: 100%;
    height: 282px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0px;
    pointer-events:none;
}

.shelf::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.shelf-item {
    width: auto;
    height: 250px;
    flex-shrink: 0;
}

.shelf-scroll-left {
    height: 100%;
    width: 60px;
    background-color: #00000088;
    pointer-events: auto;
}

.shelf-scroll-right {
    height: 100%;
    width: 60px;
    background-color: #00000088;
    pointer-events: auto;
}

.scroll-arrow {
    flex-grow: 0;
}

.friend-tabs {
    border-bottom: none!important;
}

.friend-tab {
    border-bottom-style: solid!important;
    border-bottom-width: 2px!important;
    border-bottom-color: rgb(222, 226, 230)!important;
    border-radius: 0px!important;
    color: white!important;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 0.8em;
}

@media only screen and (min-width: 768px) {
    .friend-tab {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 1em;
    }

    .navbar-friends {
        height: 190px;
    }
} 

.friend-tab.active {
    background-color: black!important;
    border-bottom-color: black!important;
}

.full-width {
    width: 100vw;
    margin-left: -31px;
    margin-right: -31px;
}